import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const promotionsMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL),
    textKey: 'pageTitles.brokerPortal',
  },
  // {
  //   to: getPathFromRouteKey(RouteKeys.LOYALTY),
  //   textKey: 'pageTitles.eliteLoyaltyProgram',
  // },
  {
    to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL.SLIDING_SCALE_CALCULATOR),
    textKey: 'pageTitles.slidingScaleCalculator',
  },
];

export default promotionsMenu;
