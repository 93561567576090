import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { LocaleNameSpaceKeys } from '../../localization/translations';
import Link from '../Link/Link';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../config/RouteKeys';
import { Accordion, AccordionItem } from '../Accordion/Accordion';
import { useLocaleContext } from '../../context/Locale';
import ButtonLink from '../ButtonLink/ButtonLink';
import { IconTypes } from '../Icon/Icon';
import { getTableRows } from '../../shared/utils/table';
import './style.scss';

interface OwnProps {
  className?: string;
  edges?: any;
  path: string;
}

type Props = OwnProps;

const BrokerPromotion: React.FC<Props> = ({ edges }) => {
  const { t } = useTranslation(LocaleNameSpaceKeys.PROMOTIONS);
  const { activeLocaleTag } = useLocaleContext();

  // const points: Array<any> = t('pointsBlocksTwo', { returnObjects: true });

  // const getRewardPointsRows = () => {
  //   return points.map((pointArray: any, i: number) => {
  //     return (
  //       <tr key={`${pointArray.toString()}-${i}`}>
  //         {pointArray.map((point: any, j: number) => {
  //           let className = '';

  //           if (point.cs === '2') {
  //             className += 'full';
  //           }

  //           return (
  //             <td
  //               key={`${point.val}-${i}-${j}`}
  //               rowSpan={point.rs}
  //               colSpan={point.cs}
  //               className={className}
  //               dangerouslySetInnerHTML={{ __html: point.val }}
  //             />
  //           );
  //         })}
  //       </tr>
  //     );
  //   });
  // };

  const imageMapper = edges.reduce((accum: any, curr: any) => {
    const paths = curr.node.name.split('-');
    const next = { ...accum };

    if (next[paths[0]]) {
      next[paths[0]][paths[1]] = curr.node.publicURL;
    } else {
      next[paths[0]] = {
        [paths[1]]: curr.node.publicURL,
      };
    }

    return next;
  }, {});

  const firstRowPoints: Array<any> = t('hatTrick.pointsBlocksFirstRow.badgeOrder', {
    returnObjects: true,
  });

  const getRewardPointsFirstRow = () => {
    return firstRowPoints.map((point: string, j: number) => (
      <td key={`point-${j}`}>
        <img src={imageMapper[point][activeLocaleTag]} width="100%" height="auto" alt={point} />
      </td>
    ));
  };

  return (
    <div className="BrokerPromotion">
      {/* <div className="BrokerPromotion__section-gap">
        <div className="BrokerPromotion__generic-gap">
          <h2>
            <Trans t={t} i18nKey="hatTrick.heading" />
          </h2>
        </div>
        <div className="BrokerPromotion__generic-gap">
          <h3 className="h4">
            <Trans t={t} i18nKey="headingSix" />
          </h3>
          <p>
            <Trans t={t} i18nKey="hatTrick.bodyTwo" />
          </p>
        </div>
        <div className="BrokerPromotion__generic-gap">
          <h3 className="h4" id="broker-promo-table">
            <Trans t={t} i18nKey="hatTrick.tableHeading" />
          </h3>
          <table aria-labelledby="broker-promo-table">
            <tbody>
              <tr>
                <td key="empty" />
                {getRewardPointsFirstRow()}
              </tr>
              {getTableRows(t('hatTrick.pointsBlocks', { returnObjects: true }))}
            </tbody>
          </table>
        </div>
        <div className="BrokerPromotion__generic-gap">
          <h3 className="h4">
            <Trans t={t} i18nKey="hatTrick.list.heading" />
          </h3>
          <ul>
            {(t('hatTrick.list.items', { returnObjects: true }) as string[]).map(
              (listItem, index) => (
                <li key={index}>{listItem}</li>
              )
            )}
          </ul>
          <p className="info">
            <Trans i18nKey="hatTrick.bodySix" t={t} />
          </p>
          <div className="d-flex">
            <ButtonLink
              to={getPathFromRouteKey(RouteKeys.LOYALTY)}
              styleOptions={{ isInline: true }}
              iconOptions={{ icon: IconTypes.ARROW_NEXT }}
              className="center"
            >
              {t('noPromo.buttonLabel')}
            </ButtonLink>
          </div>
        </div>
        <div className="BrokerPromotion__generic-gap">
          <p className="small">
            <Trans i18nKey="hatTrick.footerNote" t={t} />
          </p>
        </div>
      </div> */}
      {/* <hr /> */}
      {/* <div className="BrokerPromotion__generic-gap">
        <h2>
          <Trans t={t} i18nKey="bodyIntro" />
        </h2>
        <p>
          <Trans t={t} i18nKey="body" />
        </p>
      </div>
      <div className="BrokerPromotion__generic-gap">
        <h3 className="h4">{t('headingSix')}</h3>
        <p>
          <Trans t={t} i18nKey="howItWorks.body" />
        </p>
      </div>
      <div className="BrokerPromotion__generic-gap">
        <h3 className="h4">{t('howItWorks.table.heading')}</h3>
        <table>
          <tbody>
            {(t('howItWorks.table.rows', { returnObjects: true }) as any[]).map((row, index) => (
              <tr key={index}>
                {(row as { value: string }[]).map((column, colIndex) => (
                  <td key={`${column.value.length}-${colIndex}`}>{column.value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <p className="small">
          <Trans i18nKey="footerNote" t={t} />
        </p>
      </div> */}

      {/* <div className="BrokerPromotion__generic-gap">
        <p>{t('howItWorks.rules.description')}</p>
        <h3>{t('howItWorks.rules.headingTwo')}</h3>
        <p className="h6">
          <Trans t={t} i18nKey="howItWorks.rules.headingSix" />
        </p>
        <Accordion allowZeroExpanded allowMultipleExpanded>
          {(t('howItWorks.rules.body', { returnObjects: true }) as {
            heading: string;
            description: string;
            link?: string;
          }[]).map((rule) => (
            <AccordionItem buttonOptions={{ label: rule.heading }} key={rule.heading}>
              <p>
                <Trans
                  components={[
                    <Link to={getPathFromRouteKey(RouteKeys.PRIVACY_STATEMENT)} key="privacy" />,
                  ]}
                >
                  {rule.description}
                </Trans>
              </p>
            </AccordionItem>
          ))}
        </Accordion>
      </div> */}

      {/* <div className="BrokerPromotion__generic-gap">
          <p>
            <Trans i18nKey="contactBDM" t={t} />
          </p>
          <ButtonLink
            to={getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM)}
            styleOptions={{ isInline: true }}
            iconOptions={{ icon: IconTypes.ARROW_NEXT }}
          >
            {t('buttonLabel')}
          </ButtonLink>
        </div> */}
      <div className="BrokerPromotion__generic-gap">
        <p>
          <Trans i18nKey="noPromo.description" t={t} />
        </p>
        {/* <ButtonLink
            to={getPathFromRouteKey(RouteKeys.LOYALTY)}
            styleOptions={{ isInline: true }}
            iconOptions={{ icon: IconTypes.ARROW_NEXT }}
          >
            {t('noPromo.buttonLabel')}
          </ButtonLink> */}
      </div>
    </div>
  );
};

export default BrokerPromotion;
