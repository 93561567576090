import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import BrokerPromotion from '../../components/BrokerPromotion/BrokerPromotion';
import PageMenu from '../../components/PageMenu/PageMenu';
import promotionsMenu from '../../data/page-menus/promotions';
import { useLocaleContext } from '../../context/Locale';
import './style.scss';

type Props = PageProps & WithTranslation;

const Promotions: React.FC<Props> = (props) => {
  const {
    t,
    location: { pathname },
  } = props;

  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.FOREST_GREEN,
        heroImage: {
          fileName: 'hero-promo-Feb2024',
          alt: 'Image',
        },
      },
    },
  };

  const {
    allFile: { edges },
    imageEN,
    imageFR,
  } = useStaticQuery(graphql`
    query PromotionsQuery {
      allFile(filter: { relativePath: { regex: "/elite-loyalty/" }, ext: { eq: ".svg" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      imageEN: file(relativePath: { eq: "home-page/promo/promo-en.jpg" }) {
        publicURL
      }
      imageFR: file(relativePath: { eq: "home-page/promo/promo-fr.jpg" }) {
        publicURL
      }
    }
  `);

  const sidebarImage = activeLocaleTag === 'en' ? imageEN.publicURL : imageFR.publicURL;

  return (
    <Layout options={layoutOptions} className="Promotions">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <BrokerPromotion edges={edges} path={pathname} />
          </section>
          <aside className="column column-33">
            {/* <div className="image-container Promotions__generic-gap">
              <img src={sidebarImage} alt="promo" />
            </div> */}
            <PageMenu
              menu={promotionsMenu}
              menuTitle={t(`${LocaleNameSpaceKeys.COMMON}:pageMenuTitles.brokerResources`)}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.PROMOTIONS)(Promotions);
